import logger from 'helpers/logger';
import { ErrorBoundary } from 'react-error-boundary';
import { capitalizeFirstLetter } from 'helpers/string';
import { NODE_ENV } from '../../_constants';
import dynamic from 'next/dynamic';

// Dynamic import can't be used inside of React rendering as it needs to be marked in the top level of the module for preloading to work, similar to React.lazy.
// https://nextjs.org/docs/pages/building-your-application/optimizing/lazy-loading#examples

// Don't get tempted to dynamically generate the imports, eg. with a forEach loop and template literals or similar.
// Tried and tested, it just wont work. It fails silently by disabling SSR and initial hydration of data!
// From the documentation: In import('path/to/component'), the path must be explicitly written. It can't be a template string nor a variable.

// Add a new feature by adding it to the imports
const features = {
	AnchorFeature: dynamic(() => import('../Anchor/AnchorFeature').then((module) => module['AnchorFeature'])),
	ArticleMediaFeature: dynamic(() => import('../ArticleMedia/ArticleMediaFeature').then((module) => module['ArticleMediaFeature'])),
	ArticleTextFeature: dynamic(() => import('../ArticleText/ArticleTextFeature').then((module) => module['ArticleTextFeature'])),
	ArticleListFeature: dynamic(() => import('../ArticleList/ArticleListFeature').then((module) => module['ArticleListFeature'])),
	CaseSliderFeature: dynamic(() => import('../CaseSlider/CaseSliderFeature').then((module) => module['CaseSliderFeature'])),
	ContactPersonFeature: dynamic(() => import('../ContactPerson/ContactPersonFeature').then((module) => module['ContactPersonFeature'])),
	ClusterFeature: dynamic(() => import('../Cluster/ClusterFeature').then((module) => module['ClusterFeature'])),
	ContentSectionFeature: dynamic(() => import('../ContentSection/ContentSectionFeature').then((module) => module['ContentSectionFeature'])),
	EmployeeListFeature: dynamic(() => import('../EmployeeList/EmployeeListFeature').then((module) => module['EmployeeListFeature'])),
	FooterCardFeature: dynamic(() => import('../FooterCard/FooterCardFeature').then((module) => module['FooterCardFeature'])),
	FormFeature: dynamic(() => import('../Form/FormFeature').then((module) => module['FormFeature'])),
	HeroCardFeature: dynamic(() => import('../HeroCard/HeroCardFeature').then((module) => module['HeroCardFeature'])),
	LogoListFeature: dynamic(() => import('../LogoList/LogoListFeature').then((module) => module['LogoListFeature'])),
	MediaGridFeature: dynamic(() => import('../MediaGrid/MediaGridFeature').then((module) => module['MediaGridFeature'])),
	NewsletterSignupFeature: dynamic(() => import('../NewsletterSignup/NewsletterSignupFeature').then((module) => module['NewsletterSignupFeature'])),
	NewsListFeature: dynamic(() => import('../NewsList/NewsListFeature').then((module) => module['NewsListFeature'])),
	ScriptModuleFeature: dynamic(() => import('../ScriptModule/ScriptModuleFeature').then((module) => module['ScriptModuleFeature'])),
	ServiceLinkListFeature: dynamic(() => import('../ServiceLinkList/ServiceLinkListFeature').then((module) => module['ServiceLinkListFeature'])),
	VideoCardFeature: dynamic(() => import('../VideoCard/VideoCardFeature').then((module) => module['VideoCardFeature'])),
	TextComponentFeature: dynamic(() => import('../TextComponent/TextComponentFeature').then((module) => module['TextComponentFeature'])),
	TestimonialSliderFeature: dynamic(() =>
		import('../TestimonialSlider/TestimonialSliderFeature').then((module) => module['TestimonialSliderFeature']),
	),
	ThreeColumnSpotFeature: dynamic(() => import('../ThreeColumnSpot/ThreeColumnSpotFeature').then((module) => module['ThreeColumnSpotFeature'])),
	MediaSpotFeature: dynamic(() => import('../MediaSpot/MediaSpotFeature').then((module) => module['MediaSpotFeature'])),
	TileListFeature: dynamic(() => import('../TileList/TileListFeature').then((module) => module['TileListFeature'])),
};

export interface ModuleFeatureProps {
	contentModule: Content.ContentModule;
}

export const ModuleFeature: React.FC<ModuleFeatureProps> = ({ contentModule }) => {
	const production = NODE_ENV === 'production';

	const { documentType } = contentModule;

	const moduleName = `${capitalizeFirstLetter(documentType)}`;
	const moduleFeatureName = `${moduleName}Feature`;

	const DynamicModule = features[moduleFeatureName];

	const message = `Component [${documentType}] is missing a React Component. Add missing export '${moduleName}' from '/features`;

	if (!DynamicModule && !production) {
		return <div>{message}</div>;
	}

	if (!DynamicModule && production) {
		logger.error(message);
		return null;
	}

	const errorHandler = (error: Error, info: { componentStack: string }) => {
		logger.error(`Error with component [${documentType}]: ${error}. ${info.componentStack}`);
	};

	return (
		<ErrorBoundary FallbackComponent={() => null} onError={errorHandler}>
			<DynamicModule {...contentModule} />
		</ErrorBoundary>
	);
};
