import classNames from 'classnames';
import styles from './OfficeCard.module.scss';
import { Heading } from 'ui/components/1-atoms/Heading/Heading';
import { Paragraph } from 'ui/components/1-atoms/Paragraph/Paragraph';

export interface OfficeProps {
	className?: string;
	heading?: string;
	address?: string;
	zipcode?: string;
}

export const OfficeCard: React.FC<OfficeProps> = ({ className, heading, address, zipcode }) => (
	<div className={classNames(styles.OfficeCard, className)}>
		<div className={styles.OfficeCard_textbox}>
			{heading && (
				<Heading headingLevel="h3" className={styles.OfficeCard_heading}>
					{heading}
				</Heading>
			)}
			<div className={styles.OfficeCard_text}>
				{address && <Paragraph>{address}</Paragraph>}
				{zipcode && <Paragraph>{zipcode}</Paragraph>}
			</div>
		</div>
	</div>
);
