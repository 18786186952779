import classNames from 'classnames';
import styles from './Paragraph.module.scss';

export interface ParagraphProps {
	children: React.ReactNode;
	className?: string;
	textAlignRight?: boolean;
	bold?: boolean;
}

export const Paragraph: React.FC<ParagraphProps> = ({ children, className, textAlignRight, bold }) => (
	<p className={classNames(styles.Paragraph, { [styles[`Paragraph___right`]]: textAlignRight, [styles[`Paragraph___bold`]]: bold }, className)}>
		{children}
	</p>
);
