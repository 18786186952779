import classNames from 'classnames';
import styles from './ContactCard.module.scss';
import { Heading } from 'ui/components/1-atoms/Heading/Heading';
import { Link } from 'ui/components/1-atoms/Action/Link/Link';
import { Paragraph } from 'ui/components/1-atoms/Paragraph/Paragraph';

export interface ContactProps {
	className?: string;
	heading?: string;
	telephone?: string;
	email?: string;
}

export const ContactCard: React.FC<ContactProps> = ({ className, heading, telephone, email }) => (
	<div className={classNames(styles.ContactCard, className)}>
		<div className={styles.ContactCard_textbox}>
			{heading && (
				<Heading headingLevel="h6" style="xs" className={styles.ContactCard_heading}>
					{heading}
				</Heading>
			)}
			<div className={styles.ContactCard_text}>
				{telephone && (
					<Link style="sm" href={`tel:${telephone}`}>
						{telephone}
					</Link>
				)}
				{email && (
					<Link style="sm" href={`mailto:${email}`}>
						{email}
					</Link>
				)}
			</div>
		</div>
	</div>
);
