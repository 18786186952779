import classNames from 'classnames';
import styles from './Footer.module.scss';
import { Heading } from 'ui/components/1-atoms/Heading/Heading';
import { Icon } from 'ui/components/1-atoms/Media/Icon/Icon';
import PentiaLogo from 'ui/icons/pentia_name_logo.svg';
import KpLogo from 'ui/icons/klausenpartners_logo_tagline.svg';
import { Link } from 'ui/components/1-atoms/Action/Link/Link';
import { OfficeCard, OfficeProps } from 'ui/components/2-molecules/Card/OfficeCard/OfficeCard';
import { ContactCard } from 'ui/components/2-molecules/Card/ContactCard/ContactCard';
import { Paragraph } from 'ui/components/1-atoms/Paragraph/Paragraph';
import { Container } from '../Container/Container';

export interface FooterProps {
	className?: string;
	heading?: string;
	office?: Array<OfficeProps>;
	ctaLink?: {
		url: string;
		target?: string;
		name?: string;
	};
	ctaLinkLabel?: string;
	cvrNumber?: string;
	some?: Array<SomeProps>;
	goToLinkLabel?: string;
	policies?: {
		url: string;
		target?: string;
		name?: string;
	};
	contact?: {
		telephone?: string;
		email?: string;
		heading?: string;
	};
	globalTheme?: string;
	dictionary?: { [key: string]: string };
}

export interface SomeProps {
	url: string;
	target?: string;
	name?: string;
}

export const Footer: React.FC<FooterProps> = ({
	className,
	heading,
	office,
	ctaLink,
	globalTheme,
	dictionary,
	ctaLinkLabel,
	cvrNumber,
	some,
	goToLinkLabel,
	policies,
	contact,
}) => {
	return (
		<footer className={classNames(styles.Footer, className)} role="contentinfo">
			<Container width="Standard">
				<div className={classNames(styles.Footer_topSection, className)}>
					{globalTheme !== 'kogp' && (
						<div className={classNames(styles.Footer_topSectionText, className)}>
							{heading && (
								<Heading headingLevel="h2" style="sm" className={styles.Footer_heading}>
									{heading}
								</Heading>
							)}
							{ctaLink && (
								<Link iconLink={true} href={ctaLink.url} target={ctaLink.target} title={ctaLinkLabel}>
									{ctaLink.name}
								</Link>
							)}
						</div>
					)}
				</div>
				<div className={styles.Footer_details}>
					{(globalTheme === 'pentia-dk' || globalTheme === 'pentia-se' || !globalTheme) && (
						<Icon size="2xl" className={styles.Footer_logo}>
							<PentiaLogo />
						</Icon>
					)}
					{globalTheme === 'kogp' && (
						<Icon size="auto" className={styles.Footer_logo}>
							<KpLogo />
						</Icon>
					)}
					<div className={styles.Footer_information}>
						{office?.map((office, index) => (
							<OfficeCard
								className={styles.Footer_officeCard}
								key={index}
								heading={office.heading}
								address={office.address}
								zipcode={office.zipcode}
							/>
						))}
						{(globalTheme === 'pentia-dk' || globalTheme === 'pentia-se' || !globalTheme) && (
							<>
								<ContactCard
									className={styles.Footer_contactCard}
									telephone={contact?.telephone}
									email={contact?.email}
									heading={contact?.heading}
								></ContactCard>
								<div className={styles.Footer_extraInformation}>
									<Paragraph>{cvrNumber}</Paragraph>
									{some?.map((some, index) => (
										<Link key={index} href={some?.url} title={goToLinkLabel + ' ' + some.name}>
											{some?.name}
										</Link>
									))}
									<Link href={policies?.url} title={goToLinkLabel + ' ' + policies?.name}>
										{policies?.name}
									</Link>
								</div>
							</>
						)}
						{globalTheme === 'kogp' && (
							<>
								<div>
									<Heading headingLevel="h3" className={styles.OfficeCard_heading}>
										{dictionary?.ContactTitle}
									</Heading>
									<ContactCard
										className={styles.Footer_contactCard}
										telephone={contact?.telephone}
										email={contact?.email}
										heading={contact?.heading}
									></ContactCard>
								</div>
								{some && (
									<div className={styles.Footer_extraInformation}>
										<Heading headingLevel="h3" className={styles.OfficeCard_heading}>
											{dictionary?.FollowUs}
										</Heading>
										{some?.map((some, index) => (
											<Link key={index} href={some?.url} title={goToLinkLabel + ' ' + some.name}>
												{some?.name}
											</Link>
										))}
									</div>
								)}
								<div>
									<Heading headingLevel="h3" className={styles.OfficeCard_heading}>
										{dictionary?.CVR}
									</Heading>
									<Paragraph>{cvrNumber}</Paragraph>
								</div>
								<div>
									<Heading headingLevel="h3" className={styles.OfficeCard_heading}>
										{dictionary?.Legal}
									</Heading>
									<Link href={policies?.url} title={policies?.name}>
										{policies?.name}
									</Link>
								</div>
							</>
						)}
					</div>
				</div>
			</Container>
		</footer>
	);
};
